<template>
  <div class="tfw-candidate-profile position-relative">
    <div class="d-flex mx-3 mb-1 align-items-center" v-if="!isMobile">
      <h5 class="text-primary">
        Hello {{ profile.first_name }} {{ profile.surname }} |
        {{ candidateType }} | Candidate ID - {{ profile.display_uid }}
      </h5>
      <div class="d-flex ml-auto mr-2 align-items-center">
        <div class="d-flex align-items-center">
          <span>Profile Completion</span>
          <CircleProgressBar
            :progressValue="80"
            width="100px"
            color="primary"
          />
        </div>
        <span>
          <CButton class="btn d-inline" to="/home"
            ><i class="fa-solid fa-house-chimney fa-xl text-primary"></i
          ></CButton>
        </span>
      </div>
    </div>
    <CSidebar v-if="!isMobile" :fixed="true" :minimize="minimize" class="pl-3">
      <div>
        <div class="main-container">
          <div class="side-bar">
            <div
              v-for="(i, index) in sectionNames"
              :key="i.id"
              class="options-container"
              @click="gotoSection(index, block)"
              :class="[index === block ? 'active bg-primary' : '']"
            >
              <div class="option-container">
                <i :class="[i.icon, 'option-icon', 'ml-1']"></i>
                <p class="option-text">{{ i.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSidebar>

    <div v-if="isMobile">
      <div class="sidebar bg-white" :class="{ open: isSideBarOpen }">
        <div
          class="logo-details text-primary"
          @click="isSideBarOpen = !isSideBarOpen"
        >
          <i
            class="fa-solid fa-bars bx-menu"
            :class="{ 'ml-auto mr-0': isSideBarOpen }"
          ></i>
        </div>
        <ul class="px-0" @click="sidebarHide">
          <li
            @click="gotoSection(index, block)"
            :class="[index === block ? 'active bg-primary' : '']"
            class="nav-list m-0"
            v-for="(i, index) in sectionNames"
            :key="i.id"
          >
            <span class="d-flex align-items-center">
              <i :class="[i.icon, 'option-icon', 'box-search']"></i>
              <span class="links_name" v-show="isSideBarOpen">{{
                i.title
              }}</span>
              <span class="tooltip">{{ i.title }}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <LoadingOverlay v-if="!isfetch" />
    <div v-else class="side-container" :class="{ 'sidebar-open': !isMobile }">
      <span v-if="block === 0">
        <ProfessionalInfo
          ref="professionalInfo"
          @tooltipHide="tooltipHide"
        ></ProfessionalInfo>
        <div
          class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              @click="gotoStep(1)"
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
          </div>

          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              @click="gotoStep(1)"
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
          </div>
        </div>
      </span>
      <span v-if="block === 1">
        <LanguageQualification
          :candidateTypeId="candidateProfile.candidate_type_id"
          @tooltipHide="tooltipHide"
        />
        <CompetencyExam
          :candidateTypeId="candidateProfile.candidate_type_id"
          @tooltipHide="tooltipHide"
        />

        <NonMedicalInfoTalentFind
          :candidateTypeId="candidateProfile.candidate_type_id"
          @tooltipHide="tooltipHide"
        />
        <LanguageInfo @tooltipHide="tooltipHide" />
        <div
          class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              name="next_page"
              @click="gotoStep(2)"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(0)"
            >
              Back
            </button>
          </div>
          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(0)"
            >
              Back
            </button>
            <button
              name="next_page"
              @click="gotoStep(2)"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
          </div>
        </div>
      </span>
      <span v-if="block === 2">
        <MultipleFileUpload
          ref="multipleFileUpload"
          :profile="profile"
          @upload="upload"
        ></MultipleFileUpload>
        <div
          class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              @click="gotoStep(3)"
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(1)"
            >
              Back
            </button>
          </div>
          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(1)"
            >
              Back
            </button>
            <button
              @click="gotoStep(3)"
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
          </div>
        </div>
      </span>
      <span v-if="block === 3">
        <AvailabilityInfo
          ref="availabilityInfo"
          :profile="profile"
          @tooltipHide="tooltipHide"
        ></AvailabilityInfo>
        <!-- <AvailabilityWeeklyInfo :profile="profile" @change="update"></AvailabilityWeeklyInfo> -->
        <div
          class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              @click="gotoStep(4)"
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(2)"
            >
              Back
            </button>
          </div>
          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(2)"
            >
              Back
            </button>
            <button
              @click="gotoStep(4)"
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
            >
              Next
            </button>
          </div>
        </div>
      </span>
      <span v-if="block === 4">
        <ContactInfo ref="contactInfo" @tooltipHide="tooltipHide"></ContactInfo>
        <div
          class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg ml-2 mt-2"
              @click="gotoStep(5)"
            >
              Next
            </button>
            <button
              @click="gotoStep(3)"
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg ml-2 mt-2"
            >
              Back
            </button>
          </div>

          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left ml-2 mt-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              @click="gotoStep(3)"
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg ml-2 mt-2"
            >
              Back
            </button>
            <button
              name="next_page"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg ml-2 mt-2"
              @click="gotoStep(5)"
            >
              Next
            </button>
          </div>
        </div>
      </span>
      <span v-if="block === 5">
        <PersonalInfo
          :profilePhoto="profilePhoto"
          ref="personalInfo"
          @upload="photoUpload"
          @tooltipHide="tooltipHide"
        ></PersonalInfo>
        <div
          class="row-sm border-top pt-4 text-center my-3 mr-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              @click="gotoStep(6)"
            >
              Next
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(4)"
            >
              Back
            </button>
          </div>
          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left ml-2 mt-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(4)"
            >
              Back
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              @click="gotoStep(6)"
            >
              Next
            </button>
          </div>
        </div>
      </span>
      <span v-if="block === 6">
        <GDPRStatement
          ref="gdprStatement"
          @tooltipHide="tooltipHide"
        ></GDPRStatement>
        <div
          class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
        >
          <div v-if="isMobile">
            <button
              @click="navigateToHome()"
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              name="done_page"
            >
              Done
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(5)"
            >
              Back
            </button>
          </div>
          <button
            class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
            @click="navigateToHome()"
          >
            Home
          </button>
          <div v-if="!isMobile">
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
              @click="gotoStep(5)"
            >
              Back
            </button>
            <button
              class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              name="done_page"
              @click="navigateToHome()"
            >
              Done
            </button>
          </div>
        </div>
      </span>
    </div>
    <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
      <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
    </CToaster>
  </div>
</template>
<script>
import CandidateProfile from "@/pages/CandidateProfile/CandidateProfileMixins.js";
import CircleProgressBar from "@/components/reusable/Charts/CircleProgressBar";
export default {
  name: "TFWCandidateProfile",
  mixins: [CandidateProfile],
  components: {
    CircleProgressBar,
  },
  data() {
    return {
      isSideBarOpen: false,
      minimize: false,
      sectionNames: [
        {
          id: 1,
          title: "Professional Info",
          icon: "fa-solid fa-circle-info",
        },
        {
          id: 2,
          title: "Language and Non Medical Qualifications",
          icon: "fa-solid fa-user-graduate",
        },
        {
          id: 3,
          title: "Documents",
          icon: "fa-solid fa-file",
        },
        {
          id: 4,
          title: "Work Preference",
          icon: "fa-solid fa-id-card",
        },
        {
          id: 5,
          title: "Contact Information",
          icon: "fa-solid fa-phone",
        },
        {
          id: 6,
          title: "Personal Information",
          icon: "fa-solid fa-user",
        },
        {
          id: 7,
          title: "Acknowledgement",
          icon: "fa-solid fa-handshake",
        },
      ],
    };
  },
  computed: {
    candidateType() {
      return this.candidateProfile?.candidate_type?.candidate_type || null;
    },
  },
  methods: {
    gotoSection(index, currentPosition) {
      this.goTo(index, { currentPosition });
    },
    menuBtnChange() {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right"); //replacing the iocns class
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu"); //replacing the iocns class
      }
    },
    sidebarHide() {
      if (this.isSideBarOpen) {
        this.isSideBarOpen = false;
      }
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
  },

  updated() {
    return this.$store.state.candidate.block;
  },
};
</script>
<style lang="scss" scoped>
.tfw-candidate-profile {
  margin: 0 -30px !important;
}
.tooltip-inner {
  color: #fff !important;
}
.btn {
  min-width: 100px;
}
.nav-item i {
  padding: 0px 5px;
  margin: 0px 5px;
  cursor: pointer;
}

.cursor-na {
  cursor: not-allowed !important;
}
</style>
